
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import GoodsItem from './component/GoodsItem.vue';
import IntroduceItem from './component/IntroduceItem.vue';

@Component({
    name: "Mall",
    components: {
        SingleHeader,
        SingleFooter,
        GoodsItem,
        IntroduceItem,
    },
})
export default class Mall extends Vue {
    private transientHidden: boolean = false;
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private baseUrlIframe = process.env.VUE_APP_URL;
    private loading: boolean = false;
    private swiperImage: any = []; // 滚动屏图片
    private goodsIndustry: any = []; // 商品分类
    private goodsMall: any = []; // 商城类别
    private showAllType: boolean = true;    // 显示全部商城分类
    private searchParam: any = {
        pageSize: 21,
        pageNo: 1,
        keyword: '',
        goodsCategory: 0,
        mallCategory: 0,
    };
    private total: number = 0;
    private productList: any = [];
    private show: boolean = true;   // 头部、底部显示状态

    private created() {
        const {embed} = this.$route.query;
        // 路由参数存在embed=mc则隐藏顶部和底部
        if (embed && embed === 'mc') {
            this.show = false;
        } else {
            this.show = true;
        }
        this.getSwiperImage();
        this.getGoodsIndustry();
        this.getGoodsMall();
    }
    private getSwiperImage() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/mall/banners')
        .then((res: any) => {
            this.swiperImage = res;
        });
    }
    // 商品分类
    private getGoodsIndustry() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/mall/category/goods')
        .then((res: any) => {
            this.goodsIndustry = res;
        });
    }
    // 商城类别
    private getGoodsMall() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/mall/category/mall')
        .then((res: any) => {
            this.goodsMall = res;
            const appItem = res.find((item: any) => item.name === '应用');
            const { embed } = this.$route.query;
            if (embed && embed === 'mc' && appItem) {
                this.searchParam.mallCategory = appItem.id;
                this.goodsMall = [appItem];
                this.showAllType = false;
            }
            this.$nextTick(() => {
                this.getProductList();
            });
        }).catch(() => {
            this.getProductList();
        });
    }
    private getProductList(notLoading = false, scrollSwitch = false) {
        this.loading = !notLoading;
        const paramData: any = {};
        paramData.keyword = this.searchParam.keyword;
        paramData.pageSize = this.searchParam.pageSize;
        paramData.pageNo = this.searchParam.pageNo;
        paramData.mallCategory = this.searchParam.mallCategory;
        paramData.goodsCategory = this.searchParam.goodsCategory;
        paramData.notLoading = notLoading;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/mall/goods')
        .then((res: any) => {
            this.total = res.total;
            this.productList = res.list;
            if (scrollSwitch) {
                this.goAnchor();
            }
        }).finally(() => {
            this.loading = false;
        });
    }
    private getRecommend() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/mall/recommend')
        .then((res: any) => {
            // ...
        });
    }
    private selectGoodsIndustry(industryId: number) {
        if (industryId !== this.searchParam.goodsCategory) {
            this.searchParam.goodsCategory = industryId;
            this.searchParam.pageNo = 1;
            this.getProductList(true);
        }
    }
    private selectAppType(mallCategoryId: number) {
        if (mallCategoryId !== this.searchParam.mallCategory) {
            this.searchParam.mallCategory = mallCategoryId;
            this.searchParam.pageNo = 1;
            this.getProductList(true);
        }
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getProductList(true, true);
    }
    private goAnchor() {
        this.transientHidden = true; // 想返回描点的时候暂时隐藏导航栏，过1.5s后开启往上滚动显示
        document.getElementById(`J_head`)!.scrollIntoView({
            behavior: "smooth",
        });
        setTimeout(() => {
            this.transientHidden = false;
        }, 1500);
    }
    private onSearch() {
        this.total = 0;
        this.searchParam.pageNo = 1;
        this.getProductList();
    }
    private openDetailPage(item: any) {
        const goodsId = item.id;
        const mallCategory = item.mallCategory;
        const goodsPriceType = Number(item.goodsPriceType);
        this.addFootprint(goodsId);
        if (mallCategory === 1 && goodsPriceType !== 15) {
            // window.open(this.baseUrlIframe + '/v2/goods/detail.htm?goodsId=' + goodsId);
            // this.$router
            // .push({
            //     path: '/goods/detail',
            //     query: {
            //         goodsId,
            //         active: '2',
            //     },
            // }).catch((err) => err);
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            window.open(`${baseUrl}/goods/detail?goodsId=${item.id}&active=2`);
        } else if (mallCategory === 2 && goodsPriceType !== 15) {
            // window.open(this.baseUrlIframe + '/v2/demand/detail.htm?demandId=' + goodsId);
            // this.$router
            // .push({
            //     path: '/demand/detail',
            //     query: {
            //         goodsId,
            //         active: '2',
            //     },
            // }).catch((err) => err);
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            window.open(`${baseUrl}/demand/detail?goodsId=${item.id}&active=2`);
        } else if (goodsPriceType === 15) {
            // window.open(this.baseUrlIframe + '/web/detail?active=2&goodsId=' + goodsId);
            // this.$router
            // .push({
            //     path: '/detail',
            //     query: {
            //         goodsId,
            //         active: '2',
            //     },
            // }).catch((err) => err);
            const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
            window.open(`${baseUrl}/detail?goodsId=${item.id}&active=2`);
        }
    }
    private addFootprint(goodsId: any) {
        this.$httpService.putData({goodsId}, '/apiProxy/api/frontend/mall/footprint')
        .then((res: any) => {
            // ...
        });
    }
    private gotoLink(item: any) {
        if (item.image && item.image !== '') {
            window.open(item.image);
        }
    }
}
