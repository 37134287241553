
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  name: 'IntroduceItem',
})
export default class IntroduceItem extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private delHtmlTag(str: any) {
        return str.replace(/<[^>]+>/g, ""); // 去掉所有的html标记
    }
}
