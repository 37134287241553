
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  name: 'GoodsItem',
})
export default class GoodsItem extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
}
